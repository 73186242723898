.cta-overlay {
  display: flex;
  min-height: 30rem;  
  margin-left: -$gutter-side-mobile-xs;
  margin-right: -$gutter-side-mobile-xs;
  position: relative;

  @media (min-width: $breakpoint-mobile-xs) {
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-left: 0;
    margin-right: 0;
  }

  @media print {
    border-left: 5px solid $color-medium-gray;
    margin-left: 0;
    margin-right: 0;    
    padding-left: 15px;
    page-break-before: auto;
    page-break-after: auto; 
    page-break-inside: avoid; 

    &.cta-overlay--energy {
      border-color: $color-energy;
    }

    &.cta-overlay--jelly {
      border-color: $color-jelly;
    }

    &.cta-overlay--persimmon {
      border-color: $color-persimmon;
    }

    &.cta-overlay--midnight {
      border-color: $color-midnight;
    }     
  }
}

@mixin cta-overlay--narrow() {
  .cta-overlay {
    display: block;
    min-height: 0;
  }
}

.cta-overlay__title {
  color: $color-black;
  display: inline-block;
  padding-right: 1.7rem;
  position: relative;

  .cta-overlay--jelly &,
  .cta-overlay--midnight & {
    color: $color-white;  
  }

  .block--call-to-action.block--title-hidden & {
    display: none;
  }

  svg {
    height: 100%;
    top: 0;
    position: absolute;
    right: -0.1rem;
    z-index: 1;

    & + svg {
      top: 0.5rem;
      right: -0.5rem;
      z-index: 0;

      * {
        fill: rgba(0,0,0,0.7);      
      }
    }
  }  
  
  svg:first-of-type {

    * {
      fill: $color-energy;

      .cta-overlay--jelly & {
        fill: $color-jelly;
      }

      .cta-overlay--persimmon & {
        fill: $color-persimmon;
      }

      .cta-overlay--midnight & {
        fill: $color-midnight;
      }      
    }
  }

  span {
    background-color: $color-energy;
    display: block;
    padding: 0.9rem 2.6rem 0.9rem 2rem;
    position: relative;

    &:before {
      background-color: rgba(0,0,0,0.7);
      content: "";
      height: 0.5rem;
      left: 1rem;
      position: absolute;
      top: 100%;
      width: calc(100% - 1rem);
    }  

    .cta-overlay--jelly & {
      background-color: $color-jelly;
    }

    .cta-overlay--persimmon & {
      background-color: $color-persimmon;
    }

    .cta-overlay--midnight & {
      background-color: $color-midnight;
    }     
  } 
}

@media print {
  .cta-overlay__title {
    svg {
      display: none;
    }
  }
}

@mixin cta-overlay__title--narrow() {
  .cta-overlay__title span {
    padding-left: 1;

    &:before {
      left: 0;
      width: 100%;
    }
  }
}

.cta-overlay__title--mobile {
  display: none;
  margin-top: 0;
  margin-bottom: 0;
}

@mixin cta-overlay__title--mobile--narrow() {
  .cta-overlay__title--mobile {
    display: inline-block;
  }

  &.block--title-hidden.block--call-to-action .cta-overlay__title--mobile,
  .block--title-hidden.block--call-to-action .cta-overlay__title--mobile {
    display: none;
  }
}

.cta-overlay__images {
  bottom: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0;  
  width: calc(50% - 3px);  
}

@mixin cta-overlay__images--narrow {
  .cta-overlay__images {
    height: auto;
    overflow: visible;
    position: static;
    margin-top: -45px;
    width: 100%;

    @media print {
      margin-top: 1rem;
    }
  }

  &.block--title-hidden.block--call-to-action .cta-overlay__images,
  .block--title-hidden.block--call-to-action .cta-overlay__images {
    margin-top: 0;
  }
}

.cta-overlay__images-copy {
  bottom: 0;
  display: block;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(50% - 3px); 
}

@mixin cta-overlay__images-copy--narrow() {
  .cta-overlay__images-copy {
    display: none;
  }
}

.cta-overlay__title-desktop-wrapper {
  margin-right: calc(-100% - 3rem);
  width: calc(200% + 3rem);
}

.cta-overlay__title--desktop {
  display: inline-block;
  margin-top: -0.5rem;
  margin-bottom: 2rem;
  margin-left: -3rem;
  width: auto;
}

@mixin cta-overlay__title--desktop--narrow() {
  .cta-overlay__title--desktop {
    display: none;
  }
}

.cta-overlay__image {
  bottom: 0;
  filter: blur(5px);
  object-fit: cover;
  left: 0;
  height: 100%;
  max-width: 200%;
  position: absolute;
  top: 0;
  width: 200%;    
  vertical-align: middle;  
}

@mixin cta-overlay__image--narrow() {
  .cta-overlay__image {
    filter: none;
    object-fit: unset;
    height: auto;
    max-width: 100%;
    position: static;
    width: 100%;  
  }
}

.cta-overlay__image-copy {
  bottom: 0;
  object-fit: cover;
  right: 0;
  height: 100%;
  max-width: 200%;
  position: absolute;
  top: 0;
  width: 200%;
  z-index: 1;
}

.cta-overlay__summary {
  color: $color-black;
  font-weight: $font-weight-medium;  
  max-width: $rte-max-width;
  @include font-size(16, 1.4);

  .block--title-hidden.block--call-to-action & {
    margin-top: 2rem;
  }    

  p,h1,h2,h3,h4,h5,h6,ul,ol,blockquote {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cta-overlay__content {
  background-color: rgba($color-beeswax, 0.75);  
  border-bottom: none;
  padding: 0 2.5rem 1.3rem 2rem; 
  position: relative;
  width: calc(50% - 3px);
  z-index: 4;    

  .cta-overlay--jelly & {
    background-color: rgba($color-oyster, 0.75);  
  }

  .cta-overlay--persimmon & {
    background-color: rgba($color-salmon, 0.75);  
  }

  .cta-overlay--midnight & {
    background-color: rgba($color-pigeon, 0.75);  
  }
}

@mixin cta-overlay__content--narrow() {
  &.block--title-hidden.block--call-to-action .cta-overlay__summary,
  .block--title-hidden.block--call-to-action .cta-overlay__summary {
    margin-top: 0;
  }  

  .cta-overlay__content {
    background-color: $color-beeswax;  
    border-bottom: 5px solid rgba(0,0,0,0.5);
    padding: 2rem 2rem 1.3rem; 
    width: 100%;
    z-index: 1; 

    @media print {
      border-bottom: 0;
    }
  }

  .cta-overlay--jelly .cta-overlay__content {
    background-color: $color-oyster;
  }

  .cta-overlay--persimmon .cta-overlay__content {
    background-color: $color-salmon;
  }

  .cta-overlay--midnight .cta-overlay__content {
    background-color: $color-pigeon;
  }    
}

.cta-overlay__actions {
  margin-top: 1rem;
}

.cta-overlay__action {
  color: $color-black;
  display: inline-block;
  font-weight: $font-weight-bold;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  @include font-size(16, 1.4);
}

@media print {
  @include cta-overlay--narrow();
  @include cta-overlay__title--narrow();
  @include cta-overlay__title--mobile--narrow();
  @include cta-overlay__images--narrow();
  @include cta-overlay__images-copy--narrow();
  @include cta-overlay__title--desktop--narrow();
  @include cta-overlay__image--narrow();
  @include cta-overlay__content--narrow();
}

@media (max-width: $breakpoint-tablet - 1px) {
  @include cta-overlay--narrow();
  @include cta-overlay__title--narrow();
  @include cta-overlay__title--mobile--narrow();
  @include cta-overlay__images--narrow();
  @include cta-overlay__images-copy--narrow();
  @include cta-overlay__title--desktop--narrow();
  @include cta-overlay__image--narrow();
  @include cta-overlay__content--narrow();
}

@media (min-width: $breakpoint-notebook) {
  .col-md-6 .col-md-6,
  .col-md-6 .col-md-7,
  .col-md-6 .col-md-8,
  .col-md-6 .col-md-9,
  .col-md-6 .col-md-10,
  .col-md-6 .col-md-11,
  .col-md-7 .col-md-6,
  .col-md-7 .col-md-7,
  .col-md-7 .col-md-8,
  .col-md-7 .col-md-9,
  .col-md-7 .col-md-10,
  .col-md-8 .col-md-6,
  .col-md-8 .col-md-7,
  .col-md-8 .col-md-8,
  .col-md-8 .col-md-9,
  .col-md-9 .col-md-6,
  .col-md-9 .col-md-7,
  .col-md-9 .col-md-8,
  .col-md-1, 
  .col-md-2, 
  .col-md-3, 
  .col-md-4,
  .col-md-5 {    
    @include cta-overlay--narrow();
    @include cta-overlay__title--narrow();
    @include cta-overlay__title--mobile--narrow();
    @include cta-overlay__images--narrow();    
    @include cta-overlay__images-copy--narrow();
    @include cta-overlay__title--desktop--narrow();
    @include cta-overlay__image--narrow();
    @include cta-overlay__content--narrow();
  }
}   