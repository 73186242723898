.banner__image {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-site-width;
  width: 100%;

  img {
    vertical-align: top;
  }
}

.banner__image--desktop {
  margin-top: 1rem;

  @media (max-width: $breakpoint-mobile-landscape - 1px) {
    display: none;
  }
}

.banner__image--mobile {
  margin-top: 0.5rem;

  @media (min-width: $breakpoint-mobile-landscape) {
    display: none;
  }
}