
.qq-hide {
  display: none;
}

.qq-uploader {
  position: relative;
}

.qq-uploader-wrapper {
  border: 1px solid rgba(0,0,0,0.5);
  box-shadow: inset 0 5px 0 $color-light-gray;
  border-radius: 2px;
  transition: all $transition-duration-default ease;
}

.qq-uploader-wrapper-inner {
  background-color: rgba(240, 240, 240, 0.5);
  box-shadow: inset 0 5px 0 $color-light-gray;
  transition: all $transition-duration-default ease;

  .qq-upload-drop-area-active + .qq-uploader-wrapper & {
    background-color: rgb(255, 246, 224);
    box-shadow: inset 0 5px 0 $color-light-gray;
  }
}

.qq-uploader-controls {
  min-height: 25rem;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  text-align: center;
}

.qq-upload-drop-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 30px;
  z-index: 3;
}

.qq-upload-label {
  color: $color-black;
  font-family: $font-ibm-plex-condensed;
  margin-bottom: 3rem;
  margin-top: 3rem;
  @include font-size(18, 1.6);
}

.qq-upload-button-wrapper {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.qq-upload-button-selector {
  padding: $button-bg-offset $button-bg-offset 0;
  position: relative;
  z-index: 2;
  vertical-align: top;

  &:before {
    background-color: $color-mineshaft;
    border-radius: 2px;
    bottom: 0;
    content: "";
    height: calc(100% - #{$button-bg-offset});
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  input {
    width: 100%;
    z-index: 4;
  }

  & + .focus-indicator {
    @include jam-focus-inner();
    border-radius: 2px;
    display: block;
    height: 100%;
    opacity: 0;    
    position: absolute;
    top: 0;
    width: 100%;  
    z-index: 0;  
  }

  &.qq-upload-button-focus + .focus-indicator {
    opacity: 1;
  }
}

.qq-upload-button-browse {
  background-color: $color-medium-gray;
  border: none;
  border-radius: 4px 4px 0 0;
  color: $color-white;
  font-family: $font-montserrat;
  font-weight: $font-weight-extrabold;
  min-width: 15rem;
  outline: 0;
  padding: 1.8rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: -$button-bg-offset;
  transition: all $transition-duration-default;
  z-index: 2;
  @include font-size(20, 1.2);

  .qq-upload-button-hover & {
    background-color: darken($color-medium-gray, 10%);
  }  
}

.qq-upload-description {
  color: $color-mineshaft;
  font-family: $font-ibm-plex-condensed;
  font-style: italic;
  font-weight: $font-weight-regular;
  margin: 1.8rem 0;
  @include font-size(18, 1.6);

  span {
    color: $color-mineshaft;
  }
}

/* Progress Bar
------------------------------------------ */
.qq-total-progress-bar-container {
  display: none;
}

/* Uploaded Elements
------------------------------------------ */
.qq-upload-list {
  margin: 0 1rem 0;
  padding: 0;
  list-style: none;
}

.qq-upload-list li {
  background-color: rgb(222, 229, 237);
  border: 1px solid $color-mineshaft;
  border-radius: 2px;
  box-shadow: inset 0 -5px 0 rgba(0,0,0,0.4);
  color: $color-black;
  font-weight: $font-weight-regular;
  margin: 0 0 0.3rem;
  padding: 1.2rem 1rem 1.7rem 5.5rem;
  position: relative;
  @include font-size(24, 1.2);

  &:last-child {
    margin-bottom: 1rem;
  }
}

.qq-upload-delete-selector {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  align-items: center;
  appearance: none;
  display: flex;
  height: 5.8rem;
  justify-content: center;
  top: 0;
  left: 0;
  padding: 1.6rem 1.5rem;
  position: absolute;
  width: 5.5rem;

  svg {
    height: 20px;
    width: 20px;
    vertical-align: top;

    * {
      fill: $color-bismark;
    }
  }
  span {
    @include element-invisible();
  }

  &:hover {
    svg * {
      fill: $color-midnight;
    }
  }

  &.qq-hide {
    display: none;
  }
}

.qq-upload-file {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.qq-upload-status-text {
}
.qq-upload-list li.qq-upload-success {
}
.qq-upload-list li.qq-upload-fail {
  background-color: $color-salmon;
  padding-left: 1rem;
}

.qq-upload-status-text {
  font-weight: $font-weight-regular;
  font-style: italic;
  margin-left: 1rem;
}