.teaser__supertitle {
  margin-bottom: 0.3rem;
  text-transform: uppercase;
  @include font-size(16, 1.4);
}

.teaser__title {
  display: inline-block;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-semibold;
  padding-bottom: 0.6rem;
  padding-top: 0.4rem;
  @include font-size(20, 1.4);
}

.teaser__title--has-icon {
  padding-left: $teaser-icon-padding + 0.4rem;
  position: relative;

  svg {
    height: 3.2rem;
    left: -0.1rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.2rem;
    width: 3.2rem;
  }
}

.teaser__summary {
  max-width: $rte-max-width;
  
  .teaser__title--has-icon + & {
    padding-left: $teaser-icon-padding;
  }

  .featured-content--list & {
    display: none;
  }

  .featured-content--list .teaser--staff & {
    display: block;
  }
}

.teaser__role {
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  text-transform: uppercase;
  @include font-size(16, 1.4);
}

.teaser__contact-items {
  list-style: none;
  margin: 0 0 1.3rem;
  padding: 0;

  .featured-content--list & {
    display: none;
  }
}

.teaser__contact-item {
  margin: 0;
  padding: 0;
}

.teaser__contact-link {
  display: inline-block;
  padding-top: 0.9rem;
  padding-left: $teaser-icon-padding + 0.4rem;
  padding-bottom: 1rem;
  @include font-size(18, 1.4);

  svg {
    height: 3.2rem;
    left: -0.1rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.6rem;
    width: 3.2rem;
  }
}
