.listing {
}

.listing__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listing__list-item {
  padding: 0;
  margin-bottom: 4rem;
}

.listing__results-status {
  margin-bottom: $default-margin;
}

.listing__results-status--no-results {
  margin-bottom: $large-margin;
}