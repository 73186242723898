.footer-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-navigation__item {
  margin-bottom: 1.6em;
  padding-left: 0;
  @include font-size(16, 1.6);
}

.footer-navigation__link {
  font-weight: $font-weight-semibold;
  margin-top: -1rem;
  margin-bottom: -1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;  
  display: inline-block;
}