.table-data {
  width: 100%;

  td,th {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;    
  }

  thead {
    th {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  td {
    text-align: right;
  }

  th {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  tr.total td {
    font-weight: $font-weight-bold;
    background-color: $color-oyster;

    &#total {
      text-align: left;
    }
  }
}