.accordion {
  ul.controls {
    display: flex;
    justify-content: flex-end;

    li {
      list-style: none;
      display: flex;
      margin-bottom: 0;

      button[type="button"] {
        @include button-submit();
      }

      button.open-all,
      button.close-all {
        margin: 0.8rem 0.8rem 0 0.4rem;
        min-width: 10rem;
        padding: 1.2rem;
        font-size: 1.4rem;
        background-color: $color-jelly;

        &:hover {
          background-color: $color-jelly-dark;
        }
      }
    }
  }
}

.accordion__item {
  border: 1px solid $color-jelly;
  border-radius: 3px;
  margin-bottom: 0.5rem;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 2.1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.accordion__toggle-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.accordion__toggle {
  background-color: $color-oyster;
  border: none;
  color: $color-mineshaft;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-semibold;
  padding: 1.3rem 5.5rem 1.4rem 1rem;
  position: relative;
  text-align: left;
  width: 100%;
  @include font-size(16, 1.4);

  @media (min-width: $breakpoint-tablet) {
    padding: 2.3rem 4.3rem 2.3rem 2.8rem;
    @include font-size(20, 1.12);
  }  

  @media (min-width: $breakpoint-desktop) {
    padding: 2.3rem 6.5rem 2.3rem 5rem;
  }

  @media print {
    @include font-size(24, 1.2);
  }
}

.accordion__toggle-icon {
  position: absolute;
  right: 0.9rem;
  top: 0.9rem;

  svg {
    vertical-align: top;
  }  

  @media (min-width: $breakpoint-tablet) {
    right: 1.9rem;
    top: 1.9rem;
  }

  @media print {
    display: none;
  }  
}

.accordion__toggle-icon--expand {
  display: block;
  
  .accordion__item--active & {
    display: none;
  } 

  @media print {
    display: none;
  }   
}

.accordion__toggle-icon--collapse {
  display: none;

  .accordion__item--active & {
    display: block;
  } 

  @media print {
    display: block;
  }

  @media print {
    display: none;
  }   
}

.accordion__contents {
  display: none;
  
  .accordion__item--active & {
    display: block;
  } 

  @media print {
    display: block;
  }

  > .row {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: $breakpoint-desktop) {
      margin-left: 2.2rem;
      margin-right: 2.2rem;
    }
  }

  > .row > .block > .block__title {
    display: none;
  }
}