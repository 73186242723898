.tabs__wrapper {
  .tabs {
    @media (max-width: $breakpoint-notebook - 1px) {
      display: none;
    }
  }

  .accordion {
    @media (min-width: $breakpoint-notebook) {
      display: none;
    }
  }
}

.tabs {

}

.tabs__navigation {
  display: flex;
  list-style: none;
  margin-top: -$jam-focus-width;
  margin-left: -$jam-focus-width;
  margin-right: -$jam-focus-width;
  margin-bottom: calc((#{$jam-focus-width} * -1) - 1px);
  overflow: auto;
  padding: $jam-focus-width;

  @media (min-width: $breakpoint-tablet) {
    margin-left: ($grid-gutter-width / 2) - $jam-focus-width;
    margin-right: ($grid-gutter-width / 2) - $jam-focus-width;
  }

  @media (min-width: $breakpoint-desktop) {
    .block--container-grouped.col-md-12 & {
      margin-left: ($grid-gutter-width / 2) + $block-container-grouped-desktop-margin - $jam-focus-width;
      margin-right: ($grid-gutter-width / 2) + $block-container-grouped-desktop-margin - $jam-focus-width;
    }
  }   
}

.tabs__nav-item {
  margin: 0 0.4rem 0 0;
  padding: 0;

  &:last-child {
    margin-right: 0;
  }  
}

.tabs__tab-heading {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.tabs__tab {
  align-items: center;
  background-color: $color-oyster;
  border: 1px solid $color-jelly;
  border-radius: 3px 3px 0 0;
  color: $color-mineshaft;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-semibold;
  display: flex;
  height: 100%;
  min-height: 7rem;
  padding: 1rem;
  position: relative;
  justify-content: center;
  transition: all $transition-duration-default;
  @include font-size(16, 1.4);

  &[aria-selected="true"],
  &:hover {
    background-color: $color-white;
  }

  &[aria-selected="true"] {
    border-bottom-color: $color-white;
  }

  &:focus {
    z-index: 3;
    @include jam-focus();
  }
}

.tabs__items {
  border: 1px solid $color-jelly;
  border-radius: 3px;
}

.tabs__item {
  position: relative;

  > .row {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: $breakpoint-desktop) {
      margin-left: 2.2rem;
      margin-right: 2.2rem;
    }    
  }

  > .row > .block > .block__title {
    display: none;
  }

  &:focus {
    z-index: 3;
    @include jam-focus();
  }
}