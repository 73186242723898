.block--section {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -3;
  }
}

.section__title {
  margin-top: $default-margin;
  margin-bottom: $default-margin - 10px;
  @include fancy-h2();
}

.section-style-white {
  &:after {
    background-color: $color-white;
  }  
}

.section-style-mercury {
  &:after {
    background-color: $color-mercury;
  }  
}

.section-style-oyster {
  &:after {
    background-color: $color-oyster;
  }
}

.section-style-beeswax {
  &:after {
    background-color: $color-beeswax;
  }  
}

.section-style-pigeon {
  &:after {
    background-color: $color-pigeon;
  }    
}

.section-style-salmon {
  &:after {
    background-color: $color-salmon;
  }    
}