#admin-overlay-trigger {
  background: none;
  margin: 0;
  padding: 0;
}

#admin-overlay {
  .admin-overlay--info-table-value {
    margin-left: 0;
  } 

  a:not(.admin-overlay--edit) {
    text-decoration: none;
    
    &:hover {
      background: none;
    }
  }  
}