// Header component

#header {
  align-items: flex-start;
  border-bottom: 10px solid $color-energy;
  display: flex;
  padding-top: 1.3rem;
  padding-bottom: 1.7rem;
  @include container();

  @media (min-width: $breakpoint-notebook) {
    border-bottom: 0;
    justify-content: space-between;
    padding-top: 0.6rem;
    padding-bottom: 2rem;
  }
}

.header__logo {
  flex: 0 0 auto;
  margin-top: 1.6rem;

  svg {
    vertical-align: top;
  }

  @media (min-width: $breakpoint-notebook) {
    height: 6rem;
    margin-top: 3.4rem;
    width: 30rem;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.header__logo-link {
  display: block;
}

// Mobile
.header__mobile-actions {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    margin-right: -$gutter-side-mobile-xs;
  }

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}

.header__mobile-action-item {
  flex: 0 0 auto;
  margin: 0;
  padding: 0;

  @media (min-width: 450px) {
    margin-right: 1.5rem;
  }
}

.header__mobile-action {
  appearance: none;
  background: none;
  border: none;
  border-radius: 2px;
  color: $color-bismark;
  display: block;
  font-family: $font-ibm-plex;
  font-weight: $font-weight-bold;
  padding: 0 6px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  @include font-size(11, 1.091);
  @include jam-focus();

  svg {
    display: inline-block;
    height: 3.2rem;
    margin-bottom: 0.25rem;
    padding: 5px;
    width: 3.2rem;
  }

  span {
    display: block;
  }

  &:hover {
    background-color: rgba($color-pigeon, 0.6);
  }  

  &:focus,
  &:active,
  &.active {
    background-color: $color-azalea;
    color: $color-black;
    outline: 0;

    svg * {
      fill: $color-black;
    }
  }
}

// desktop
.header__desktop-group {
  flex: 0 1 68rem;
  margin-left: 1.5rem;

  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

.header__secondary-menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0 0 0.6rem;
  padding: 0;
}

.header__secondary-menu-item {
  margin: 0 0 0 1.5rem;
  padding: 0;
  white-space: nowrap;

  @media (min-width: $breakpoint-desktop) {
    margin: 0 0 0 4rem;
  }

  &:first-child {
    margin-left: 0;
  }  
}

.header__secondary-menu-link {
  display: inline-block;
  @include font-size(14, (44/14));
}