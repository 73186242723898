.contact {
  max-width: $rte-max-width;
  margin-bottom: $default-margin;
}

.contact__group {
  margin-bottom: $default-rte-margin * 1.4;
}

.contact__recaptcha-label {
  @include element-invisible();
}