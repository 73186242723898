.filter {
  margin-bottom: 1rem;
  @include clearfix();
}

.filter__primary {
  float: left;
  margin-right: 1.5rem;
}

.filter__dropdown {
  align-items: center;
  background-color: $color-mercury;
  border: none;
  border-radius: 4px 4px 0 0;
  color: $color-mineshaft;
  display: flex;
  font-family: $font-montserrat;
  font-weight: $font-weight-extrabold;
  justify-content: center;
  margin-bottom: $button-bg-offset + 11px;
  margin-left: $button-bg-offset;
  margin-right: $button-bg-offset;
  min-width: 19rem;
  outline: 0;
  padding: 1.8rem;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: all $transition-duration-default;
  @include font-size(20, 1.2);

  &:before {
    background-color: rgb(153, 153, 153);
    border-radius: 2px;
    bottom: -$button-bg-offset;
    content: "";
    height: 100%;
    left: -$button-bg-offset;
    right: -$button-bg-offset;
    position: absolute;
    z-index: -1;
  }

  &:focus {
    @include jam-focus();
  }

  &:active {
    bottom: 0;
  }

  &.disabled,
  &[disabled] {
    background-color: $color-mercury;

    &:before {
      background-color: rgb(179, 179, 179);
    }
  }

  &:hover  {
    background-color: darken($color-mercury, 5%);
  }
  
  svg {
    margin-right: 4px;
  }
}

.filter__menu {
  background-color: $color-mercury;
  border: none;
  border-radius: 0 4px 4px 4px;
  box-shadow: none;
  margin: 0 0 0 $button-bg-offset;
  min-width: 27rem;
  padding: 0 0 1.2rem;

  button[type="submit"] {
    margin-bottom: 2rem;
    margin-left: 2rem;
    min-width: 14rem;
  }
}

.filter__items-list {
  margin-bottom: 1rem;
  position: relative;

  &:before {
    @include scrolling-shadow-top();
  }

  &:after {
    @include scrolling-shadow-bottom();
  }

  &.filter__items-list--at-top:before {
    opacity: 0;
    visibility: hidden;
  }

  &.filter__items-list--at-bottom:after {
    opacity: 0;
    visibility: hidden;    
  }  
}

.filter__items-list-scroller {
  max-height: 31.5rem;
  padding-left: 2rem;
  padding-right: 2rem;  
  -webkit-overflow-scrolling: touch;
  overflow: auto;

  .form-group {
    margin-top: 0.5rem;
  }
}

.filter__menu-list {
  list-style: none;
  padding: 0.5rem 2rem 0;
  margin: 0;
}

.filter__menu-list-item {
  margin: 0;
  padding: 0;
}

.filter__menu-link {
  display: block;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  padding: 1rem;
  margin: 0;
  text-decoration: none;
  @include font-size(20, 1.25);
}

// results
.filter__result {
  background-color: $color-beeswax;
  border-radius: 4px;
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem 5.5rem 1.1rem 1.5rem;
  position: relative;
}

.filter__result-label {
  color: rgb(113, 84, 4);
  font-weight: $font-weight-medium;
  margin-bottom: 0.1rem;
  @include font-size(14, 1.286);
}

.filter__result-value {
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  @include font-size(16, 1.25);
}

.filter__result-close {
  background-color: $color-energy;
  background-repeat: no-repeat;
  background-image: url('../core/images/close.png');
  background-size: 20px 20px;
  background-position: center center;
  bottom: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;

  &:hover {
    background-color: darken($color-energy, 10%);
  }

  span {
    @include element-invisible();
  }
}

.filter__clear {
  display: inline-block;
  text-decoration: none;
}

.filter__clear-inner {
  align-items: center;
  color: $color-medium-gray;
  display: flex;
  font-family: $font-montserrat;
  font-weight: $font-weight-extrabold;
  height: 6rem;
  text-transform: uppercase;
  @include font-size(14, 1.286);

  svg {
    background-color: $color-medium-gray;
    border-radius: 100%;
    height: 3rem;
    margin-right: 1rem;
    padding: 1rem;
    width: 3rem;

    * {
      fill: $color-white;
    }
  }  
}

.hidden {
  display: none;
}