.primary-navigation {
  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

// top level
.primary-navigation__menu-wrapper {
  background-color: $color-jelly;
  border-bottom: 5px solid $color-nordic;
}

.primary-navigation__menu-inner {
  @include container();
}

.primary-navigation__menu {
  display: flex;
  height: 6rem;
  list-style: none;
  margin: 0 0 0;
  padding: 0;
}

.primary-navigation__menu-item {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
}

.primary-navigation__menu-link {
  align-items: center;
  border-top: 10px solid transparent;
  border-top: 5px solid transparent;
  border-radius: 2px 2px 0 0;
  color: $color-white;
  display: flex;
  justify-content: center;
  height: calc(100% + 15px);
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-medium;
  margin: -10px 0 -5px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  @include font-size(20, 1.4); 

  &:hover,
  &:active {
    background-color: transparent;
    color: $color-white;

    span {
      text-decoration: underline;
    }
  }

  &:focus {
    position: relative;
    z-index: 3;
  }

  &.primary-navigation__menu-link--active {
    background-color: $color-oyster;
    border-top: 10px solid $color-oyster;
    border-top: 5px solid $color-oyster;    
    color: $color-midnight;
  }  
}

a.primary-navigation__menu-link.primary-navigation__menu-link--active {
  text-decoration: underline;
}

// secondary level
.primary-navigation__secondary-menu-wrapper {
  background-color: $color-oyster;
}

.primary-navigation__secondary-menu-inner {
  @include container();
}

.primary-navigation__secondary-menu {
  display: flex;
  height: 6rem;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;  

  &.primary-navigation__secondary-menu--centered {
    justify-content: center;
  }
}

.primary-navigation__secondary-menu-item {
  flex: 0 1 auto;
  margin: 0;
  padding: 0;
}

.primary-navigation__secondary-menu-link {
  align-items: center;
  color: $color-midnight;
  display: flex;
  height: 100%;
  justify-content: center;  
  margin: 0;
  position: relative;
  @include font-size(16, 1.25);

  .primary-navigation__secondary-menu--centered & {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &.primary-navigation__secondary-menu-link--active {

    &:before {
      bottom: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
  
      border-bottom: 10px solid $color-white;
      content: "";
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
}

a.primary-navigation__secondary-menu-link:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

// Screenreader desciptors
.primary-navigation__menu-sr-descriptors {
  @include element-invisible();
}