/**
 * Base
 *
 * Base styles include HTML element types only, no classes or IDs
 */

html {
  font-size: $font-size-root-px;

  @media print {
    font-size: $font-size-root-px / 1.5;
  }
}

body {
  font-family: $font-ibm-plex;
  font-weight: $font-weight-regular;
  @include font-size(18, $line-height-base);

  *::-moz-selection,
  *::selection {
    background-color: $color-jam;
    color: $color-white;
  }  
}

@page { 
  margin-top: 0.75in;
} 

h1 {
  color: $color-black;
  font-family: $font-montserrat;
  font-weight: $font-weight-extrabold;
  margin-bottom: 7.5rem;
  margin-top: $page-title-margin-top;
  @include font-size(32, $line-height-heading);
}

h2 {
  color: $color-black;
  font-family: $font-montserrat;  
  font-weight: $font-weight-extrabold;
  margin-top: $default-rte-margin * 2;
  margin-bottom: $default-rte-margin;
  text-transform: uppercase;
  @include font-size(36, $line-height-heading);

  @media print {
    @include font-size(26, $line-height-heading);
  }
}

h3 {
  font-family: $font-montserrat;  
  font-weight: $font-weight-bold;
  margin-top: $default-rte-margin * 2;
  margin-bottom: $default-rte-margin;  
  @include font-size(26, $line-height-heading);

  @media print {
    @include font-size(24, $line-height-heading);
  }  
}

h4 {
  color: $color-black;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  margin-top: $default-rte-margin * 2;
  margin-bottom: $default-rte-margin;  
  @include font-size(26, 1.2);

  @media print {
    @include font-size(24, $line-height-heading);
  }  
}

h5,h6 {
  color: $color-black;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-regular;
  letter-spacing: 2.7px;
  margin-top: $default-rte-margin * 2;
  margin-bottom: $default-rte-margin;  
  text-transform: uppercase;
  @include font-size(18, 1.4);  
}

a {
  border-radius: 2px;
  color: $color-bismark;
  margin-left: -4px;
  margin-right: -4px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  transition: all $transition-duration-default ease;
  @include jam-focus;

  &:visited {
   
  }

  &:hover {
    background-color: rgba($color-pigeon, 0.6);
  }

  &:focus,
  &:active,
  &.active {
    background-color: $color-azalea;
    color: $color-black;
    outline: 0;
  }
}

p {
  margin-top: $default-rte-margin;
  margin-bottom: $default-rte-margin;
}

figure {
  margin-top: 0;
  margin-bottom: $default-rte-margin;
  margin-left: 0;
  margin-right: 0;

  img {
    vertical-align: top;
  }
}

figcaption {
  border-left: 3px solid $color-mercury;
  font-family: $font-ibm-plex-condensed;
  margin: 1.7rem 1.7rem 0;
  padding-left: 1.5rem;
  text-align: left;
  @include font-size(16, $line-height-base);

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

img,video {
  height: auto;
  max-width: 100%;

  @media print {
    page-break-before: auto;
    page-break-after: auto; 
    page-break-inside: avoid;
    position: relative;
    display: block;
    max-width: 60% !important;
  }
}

hr {
  border-bottom: 10px solid $color-energy;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin-left: -$gutter-side-mobile;
  margin-top: $default-rte-margin;
  margin-bottom: $default-rte-margin;
  max-width: 60%;

  @media (min-width: $breakpoint-tablet) {
    margin-left: -$gutter-side-tablet;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-left: -$gutter-side-notebook;
  }
}

ul,ol {
  margin-top: $default-rte-margin;
  margin-bottom: $default-rte-margin;
  padding-left: 4.5rem;

  ul,ol {
    padding-left: 2rem;
    margin-top: $default-rte-margin / 2;
    margin-bottom: 0;
  }

  ol {
    list-style-type: lower-alpha;
  }
}

li {
  margin-bottom: $default-rte-margin / 2;
  padding-left: 0.8rem;
}

blockquote {
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left: 10px solid $color-persimmon;
  color: $color-persimmon;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  font-style: italic;
  margin: $default-rte-margin 0;
  overflow: hidden;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  @include font-size(26, 1.25);
}

table {
  border-collapse: separate;
  border-spacing: 2px;
  caption-side: bottom;

  caption {
    background-color: $color-oyster;
    padding: 1.4rem 1rem;
    margin-left: 2px;
    margin-right: 2px;
    text-align: left;
  }
}

tr {
  &:nth-child(even) {
    background-color: rgb(242, 242, 242);
  }

  &:nth-child(odd) {
    background-color: $color-mercury;
  }
}

th {
  background-color: $color-jelly;
  color: $color-white;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  padding: 1.4rem $table-padding;
  text-align: left;
  text-transform: uppercase;
  @include font-size(16, $line-height-base);

  a {
    color: $color-white;
    display: block;
  }
}

td {
  padding: 0.7rem $table-padding 0.8rem;
}

article {
  margin-bottom: 2 * $default-margin;
}

// inputs
label {
  display: block;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  padding-bottom: 0.5rem;
  @include font-size(16, 1.44);
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
textarea {
  background-color: $color-white;
  border: 1px solid $color-mineshaft;
  border-radius: 2px;
  color: $color-black;
  padding: 1.6rem 1.4rem 1.4rem;
  outline: 0;
  transition: all $transition-duration-default ease;
  width: 100%;
  @include font-size(16, 1.2);

  // focus
  box-shadow: 0 0 0 2px transparent,
              0 0 0 6px transparent,
              0 0 0 8px transparent,
              inset 0 5px 0 $color-light-gray;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-medium-gray;
    opacity: 1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color-medium-gray;
    opacity: 1;
  }
 &:-ms-input-placeholder { /* IE 10+ */
    color: $color-medium-gray;
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color-medium-gray;
    opacity: 1;
  }    

  &:focus {
    border-color: $color-black;
    box-shadow: 0 0 0 2px rgb(229, 149, 184),
                0 0 0 6px rgba($color-jam, 0.85),
                0 0 0 8px rgba($color-jam, 0.25),
                inset 0 5px 0 $color-mineshaft;
  }

  &.disabled,
  &[disabled] {
    background-color: $color-mercury;
    color: rgb(107, 107, 107);

    box-shadow: 0 0 0 2px transparent,
                0 0 0 6px transparent,
                0 0 0 8px transparent,
                inset 0 5px 0 rgb(194, 194, 194);         
  }

  &:hover {
    background-color: $color-pigeon-light;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgb(240, 240, 240);
  background-image: url('../core/images/select.png');
  background-repeat: no-repeat;
  background-size: 14px 8px;
  background-position: calc(100% - 1.7rem) 2.2rem;
  border: 1px solid $color-mineshaft;
  border-radius: 2px;
  color: rgb(107, 107, 107);
  padding: 1.6rem 4rem 1.4rem 1.4rem;
  outline: 0;
  transition: all $transition-duration-default ease;
  width: 100%;
  @include font-size(16, 1.2);  

  // focus
  box-shadow: 0 0 0 2px transparent,
              0 0 0 6px transparent,
              0 0 0 8px transparent,
              inset 0 -5px 0 rgba($color-mineshaft, 0.2);

  &:focus {
    border-color: $color-black;
    box-shadow: 0 0 0 2px rgb(229, 149, 184),
                0 0 0 6px rgba($color-jam, 0.85),
                0 0 0 8px rgba($color-jam, 0.25),
                inset 0 -5px 0 rgba($color-mineshaft, 0.2);
    color: $color-black;                
  }
  
  &.disabled,
  &[disabled] {
    background-color: $color-mercury;
    background-image: url('../core/images/select--disabled.png');    
    color: rgb(107, 107, 107);
  }

  &:hover {
    background-color: $color-pigeon-light;
  }

  &:valid {
    color: $color-black;
  }
}

textarea {
  resize: vertical;
  @include font-size(18, 1.4);
}

button[type="submit"] {
  @include button-submit();
}