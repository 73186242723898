.microsite-header {
  border-bottom: 5px solid rgb(112, 126, 143);

  &.microsite-header--energy {
    border-color: rgb(190, 161, 81);
  }

  &.microsite-header--jelly {
    border-color: rgb(108, 139, 143);
  }

  &.microsite-header--persimmon {
    border-color: rgb(187, 89, 89);
  }
}

.microsite-header__container {
  padding-left: $gutter-side-microsite;
  padding-right: $gutter-side-microsite;

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    padding-left: $gutter-side-mobile-xs;
    padding-right: $gutter-side-mobile-xs;
  }
}

.microsite-header__primary {
  background-color: rgb(35, 49, 66);

  .microsite-header--energy & {
    background-color: rgb(113, 84, 4);
  }

  .microsite-header--jelly & {
    background-color: rgb(31, 62, 66);
  }

  .microsite-header--persimmon & {
    background-color: rgb(110, 12, 12);
  }
}

.microsite-header__secondary {
  background-color: rgb(26, 36, 49);

  .microsite-header--energy & {
    background-color: rgb(84, 62, 2);
  }

  .microsite-header--jelly & {
    background-color: rgb(23, 46, 49);
  }

  .microsite-header--persimmon & {
    background-color: rgb(82, 8, 8);
  }
}

.microsite-header__primary-inner {
  align-items: flex-start;
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem; 
  position: relative;

  @media (min-width: $breakpoint-notebook)  {
    padding-top: 3.3rem;
    padding-bottom: 2rem;
  }
}

$microsite-header-icon-desktop-width: 10rem;
.microsite-header__title {
  color: $color-white;
  flex: 1 1 auto;
  font-family: $font-montserrat;
  font-weight: $font-weight-bold;
  @include font-size(14, 1.2);

  @media (min-width: $breakpoint-notebook)  {
    padding-left: $microsite-header-icon-desktop-width + 2rem;
    @include font-size(22, 1.2);
  }
}

.microsite-header__icon-wrapper {
  flex: 0 0 auto;
  height: 5rem;
  margin-right: 2rem;
  width: 5rem;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media (min-width: $breakpoint-notebook)  {
    height: $microsite-header-icon-desktop-width;
    position: absolute;
    top: 2rem;
    width: $microsite-header-icon-desktop-width;
  }
}

.microsite-header__secondary-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 1.1rem;
  padding-bottom: 1rem;   

  @media (min-width: $breakpoint-notebook)  {
    justify-content: flex-end;
    padding-top: 1.9rem;
    padding-bottom: 1.7rem;
  }
}

.microsite-header__link {
  align-items: center;
  color: rgba($color-white, 0.7);
  display: flex;
  flex: 0 1 auto;
  font-weight: $font-weight-semibold;
  padding-top: 2px;
  padding-bottom: 2px;
  text-decoration: none;
  text-transform: uppercase;
  @include font-size(11, 1.091);

  svg {
    flex: 0 0 auto;
    height: 20px;
    width: 20px;

    * {
      fill: rgba($color-white, 0.7);
    }
  }

  span {
    flex: 1 1 auto;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: $color-mineshaft;

    svg * {
      fill: $color-mineshaft;
    }
  }

  &:focus {
    svg * {
      fill: $color-mineshaft;
    }
  }
}

.microsite-header__link--nav {
  margin-right: 1rem;
  svg {
    margin-right: 0.6rem;
  }

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}

.microsite-header__link--back {
  svg {
    margin-left: 0.6rem;
  }
}