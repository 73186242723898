// General
.assetblock--asset .ombuassets figure {
  margin-bottom: 0;

  img {
    @media (max-width: $breakpoint-tablet - 1px) {
      margin-left: -$gutter-side-mobile;
      margin-right: -$gutter-side-mobile;
      max-width: calc(100% + (2 * #{$gutter-side-mobile}));

      .block--container-grouped & {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
      }      
    }  
        
    @media (max-width: $breakpoint-mobile-xs - 1px) {
      margin-left: -$gutter-side-mobile-xs;
      margin-right: -$gutter-side-mobile-xs;
      max-width: calc(100% + (2 * #{$gutter-side-mobile-xs}));

      .block--container-grouped & {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
      }      
    }  
  }
}

.assetblock--asset .ombuassets figcaption {
  margin-left: 0;
}

// Videos
.aspect-ratio-box {
  display: block;
  width: 100%;
  position: relative;

  .aspect-prop {
    display: block;
    position: relative;

    & + * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  @media print {
    display: none;
  }  
}

.ombuassets-video {
  @media print {
    &:before {
      content: attr(data-title);
    }
    &:after {
      content: " (" attr(data-url) ")";
    }    
  }
}

.ombuassets-video__transcripts {
  margin-top: 1.7rem;
}

.ombuassets-video__transcript {
  display: inline-block;
  margin-right: 2.3rem;
  padding-bottom: 0.6rem;
  padding-top: 0.4rem;
  padding-left: 4rem;  
  position: relative;  
  @include font-size(18, 1.8);

  &:last-child {
    margin-right: 0;
  }

  svg {
    height: 3.2rem;
    left: -0.1rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.6rem;
    width: 3.2rem;
  } 
}

.ombuassets-video__transcript--audio-description {
  svg {
    padding: 0.3rem;
  }
}

// Document
.ombuassets-document {
  display: inline-block;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-semibold;
  padding-bottom: 0.6rem;
  padding-top: 0.4rem;
  padding-left: $teaser-icon-padding + 0.4rem;
  position: relative;  
  text-decoration: none;
  @include font-size(20, 1.4);

  svg {
    height: 3.2rem;
    left: -0.1rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.2rem;
    width: 3.2rem;
  }
}

.ombuassets-document__title {
  text-decoration: underline;
}

.ombuassets-document__type {
  color: $color-mineshaft;
  font-weight: $font-weight-regular;
  display: block;
  margin-top: 0.5rem;
  text-transform: uppercase;
  @include font-size(16, 1.4);
} 


.ombuassets-document__separator {
  display: inline-block;
  text-align: center;
  width: 3.5rem;
}