.featured-content__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.featured-content__item {
  margin: 0 0 2rem;
  padding: 0;
}

.featured-content__actions {
  margin-bottom: 2rem;
}

// grid layout

.featured-content--grid {
  @media (min-width: $breakpoint-tablet) {
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;

    .featured-content__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .featured-content__item {
      flex: 0 0 auto;
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;
      width: 100%;

      @media (min-width: $breakpoint-tablet) {
        width: 50%;
      }

      @media (min-width: $breakpoint-notebook) {
        .col-md-12 &,
        .col-md-11 &,
        .col-md-10 & {
          width: 33%;

          .col-md-10 &,
          .col-md-11 & {
            width: 50%;
          }          
        }
        
        .col-md-7 &,
        .col-md-8 &,
        .col-md-9 & {
          width: 50%;

          .col-md-7 &,
          .col-md-8 &,
          .col-md-9 &,
          .col-md-10 &,
          .col-md-11 & {
            width: 100%;
          }
        } 

        .col-md-1 &, 
        .col-md-2 &, 
        .col-md-3 &, 
        .col-md-4 &, 
        .col-md-5 &,
        .col-md-6 & {
          width: 100%;
        }
      }    
    }

    .featured-content__actions {
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;
    }
  }
}