.form {
  margin-bottom: $default-margin * 2;
  margin-top: $default-margin;
}

.form__group {
  margin-bottom: $default-rte-margin * 1.4;
  max-width: 100%;
  width: 60rem;
}

.form__text {
  max-width: 100%;
  width: 60rem;
}

.form__actions {
  margin-top: $default-rte-margin * 1.2;
}

.form__action {
  margin-bottom: 1.5rem;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $breakpoint-tablet) {
    display: inline;
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
}

.form__errors {
  color: rgb(189, 0, 0);
  font-family: $font-ibm-plex-condensed;
  font-style: italic;
  margin-bottom: $default-margin;
  @include font-size(20, 1.44);
}

.form__errors-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.form__errors-list-item {
  margin-bottom: 0.25rem;
  padding: 0;
}

.form__errors--field {
  margin-bottom: 1rem;

  label + & {
    margin-top: -1rem;
  }
}

.form__errors--field-below {
  margin-bottom: 0;
  margin-top: 1rem;
}

.form__label-supplement {
  display: inline-block;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-regular;
  margin-left: 1.2rem;
  margin-top: 0.8rem;
  padding-left: 1.1rem;
  position: relative;
  text-transform: uppercase;
  @include font-size(15, 1.0533);

  &:before {
    background-color: $color-mercury;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    height: 1.8rem;
    width: 0.1rem;
  }
}

.checkbox {
  label {
    padding-bottom: 0;

    input[type="checkbox"] {
      @include element-invisible();
    }

    span {
      border-radius: 2px;
      display: block;
      font-weight: $font-weight-regular;
      padding: 1rem 0 1rem 4.5rem;
      position: relative;
      transition: all $transition-duration-default ease;
      @include font-size(20, 1.44);

      &:before {
        background-color: $color-white;
        border: 1px solid $color-mineshaft;
        border-radius: 2px;
        box-shadow: 0 0 0 2px transparent,
                    0 0 0 6px transparent,
                    0 0 0 $jam-focus-width transparent,
                    inset 0 5px 0 rgba($color-mineshaft, 0.2);
        content: "";
        height: 3rem;
        left: 0;
        position: absolute;
        top: 1rem;
        width: 3rem;
      }

      &:hover {
        &:before {
          background-color: $color-pigeon-light;
        }
      }
    }

    input[type="checkbox"]:checked + span,
    input[type="checkbox"][checked] + span {
      &:before {
        background-image: url('../core/images/checkmark.png');
        background-repeat: no-repeat;
        background-size: 18px 15px;
        background-position: 6px 9px;
      }
    }

    input[type="checkbox"]:focus + span {
      &:before {
        box-shadow: 0 0 0 2px rgb(229, 149, 184),
                      0 0 0 6px rgba($color-jam, 0.85),
                      0 0 0 $jam-focus-width rgba($color-jam, 0.25),
                      inset 0 5px 0 $color-light-gray;        
      }
    } 

    input[type="checkbox"][disabled] + span,
    input[type="checkbox"].disabled + span {
      background-color: $color-mercury;
    }

    input[type="checkbox"][disabled]:checked + span,
    input[type="checkbox"][disabled][checked] + span,
    input[type="checkbox"].disabled:checked + span,
    input[type="checkbox"].disabled[checked] + span {
      background-image: url('../core/images/checkmark--disabled.png');
    }
  }
}

.multiple-checkbox {
  margin-bottom: 2rem;
}
