.search-input {
  position: relative;

  svg {
    left: 0.6rem;
    padding: 0.6rem;
    position: absolute;
    top: 0.6rem;
    width: 3.2rem;
    height: 3.2rem;
  }
}

input[type="text"].search-input__input {
  background-color: $color-white;
  border: 1px solid $color-pigeon;
  border-radius: 2px;
  color: $color-bismark;
  font-family: $font-ibm-plex;
  font-weight: $font-weight-regular;
  padding: 0.8rem 1.5rem 0.8rem 4.8rem;
  @include font-size(16, 1.75);
  width: 100%;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-bismark;
    opacity: 1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color-bismark;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color-bismark;
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color-bismark;
    opacity: 1;
  }

  // focus
  box-shadow: 0 0 0 2px transparent,
              0 0 0 6px transparent,
              0 0 0 8px transparent,
              inset 0 4px 0 $color-pigeon-light;

  &:focus {
    box-shadow: 0 0 0 2px rgb(229, 149, 184),
                0 0 0 6px rgba($color-jam, 0.85),
                0 0 0 8px rgba($color-jam, 0.25),
                inset 0 4px 0 $color-pigeon-light;
  }  
}

.search-input__label {
  @include element-invisible();
}

.search-input__label-wrapper {
  padding-bottom: 1.2rem;
}