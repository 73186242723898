.news-event {
  margin-bottom: 1.6rem;
}

.news-event__row {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
  }
}

.news-event__primary {
  flex: 1 1 auto;
}

.news-event__secondary {
  flex: 0 0 auto;
  order: 2;
}