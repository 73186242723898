/**
 * Constants
 */

// Theme Colors

//primary
$color-midnight: rgb(39, 45, 66);
$color-jelly: rgb(64, 128, 135);
$color-energy: rgb(250, 207, 90);
$color-persimmon: rgb(237, 90, 90);
$color-jam: rgb(170, 17, 85);
// shades
$color-bismark: rgb(71, 99, 133);
$color-pigeon: rgb(188, 202, 220);
$color-oyster: rgb(217, 235, 237);
$color-beeswax: rgb(253, 236, 191);
$color-salmon: rgb(247, 182, 182);
$color-azalea: rgb(249, 195, 219);
$color-nordic: rgb(31, 62, 66);
// minor shades
$color-bismark-dark: rgb(45, 73, 107);
$color-jelly-dark: rgb(17, 97, 105);
$color-beeswax-dark: rgb(252, 224, 150);
$color-salmon-dark: rgb(243, 141, 141);
$color-pigeon-light: rgb(238, 242, 246);
$color-bronze: rgb(186, 138, 33);
// neutrals
$color-black: rgb(0, 0, 0);
$color-nero: rgb(26, 26, 26);
$color-mineshaft: rgb(51, 51, 51);
$color-mercury: rgb(230, 230, 230);
$color-white: rgb(255, 255, 255);
$color-light-gray: rgb(214, 214, 214);
$color-medium-gray: rgb(118, 118, 118);
// other
$color-silver: rgb(128, 128, 128);


// Fonts
$font-montserrat: 'Montserrat', sans-serif;
$font-ibm-plex: 'IBM Plex Sans', sans-serif;
$font-ibm-plex-condensed: 'IBM Plex Sans Condensed', sans-serif;

// Typography
$font-size-root: 62.5%;
$font-size-root-px: 10px;
$font-size-base: 18px;
$line-height-base: 1.6;
$line-height-heading: 1.2;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-medium: 500;

// Transition effects
$transition-duration-default: 0.2s;
$transition-duration-slow: $transition-duration-default * 2;

// Gutters and breakpoints
$grid-gutter-width: 56px;
$gutter-side-mobile-xs: 5px;
$gutter-side-mobile: 20px;
$gutter-side-tablet: 40px;
$gutter-side-notebook: 40px;
$gutter-side-desktop: 80px;
$gutter-side-microsite: 20px;

$breakpoint-mobile-xs: 375px;
$breakpoint-mobile-landscape: 480px;
$breakpoint-tablet: 768px;
$breakpoint-notebook: 992px;
$breakpoint-desktop: 1200px;
$breakpoint-desktop-wide: 1280px;

$max-site-width: 1400px;
$max-asset-width: 1920px;

// Helper vars
$default-rte-margin: 1.8rem;
$default-margin: $line-height-base * $font-size-base;
$large-margin: 7rem;
$page-title-margin-top: 2rem;
$table-padding: 1rem;
$block-container-grouped-desktop-margin: 22px;
$jam-focus-width: 8px;
$rte-max-width: 35em;
$button-bg-offset: 4px;
$teaser-icon-padding: 4rem;