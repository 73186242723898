.subscription-signup {
  margin-bottom: $default-margin;
  padding: 3rem 3.5rem 3.5rem;
  position: relative;

  @media (min-width: $breakpoint-notebook) {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  &:before {
    background-color: $color-pigeon;
    content: "";
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: -2;
  }
}

.subscription-signup__title {
  color: $color-black;
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  margin-top: 0;
  margin-bottom: 2.7rem;  
  text-transform: none;
  @include font-size(26, 1.4);  
}

form.subscription-signup__form {
  margin-bottom: 0;
}