.pagination {
  margin-bottom: $default-margin;
}

.pagination__prev-next {
  padding: 0.6rem;

  &.disabled {
    color: $color-medium-gray;
  }

  &.pagination__prev-next--prev {
    margin-right: 3rem;
    margin-left: -0.6rem;
  }

  &.pagination__prev-next--next {
    margin-left: 3rem;
  }
}