// Fix for flickity fade plugin wraparound with 2 slides issue
// see https://github.com/metafizzy/flickity-fade/issues/1
.gallery__slide {
  opacity: 0 !important;
  transition: opacity $transition-duration-slow ease !important;

  &.is-selected {
    opacity: 1 !important;
  }

  @media print {
    margin-bottom: $default-rte-margin;
    opacity: 1 !important;

    &.is-selected {
      left: -50% !important;
    }
  }  
}

// Custom gallery styles
$gallery-image-ratio: 67.72%;
$gallery-content-width: 33.6rem;
$gallery-page-spacing: 0.4rem;
$gallery-pager-height: 6rem;

.gallery {
  margin-left: -$gutter-side-mobile-xs;
  margin-right: -$gutter-side-mobile-xs;
  position: relative;

  @media (min-width: $breakpoint-mobile-xs) {
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-left: 0;
    margin-right: 0;
  }

  @media print {
    margin-left: 0;
    margin-right: 0;
  }
}

.flickity-viewport {
  @media print {
    height: auto !important;
  }
}

.flickity-slider {
  @media print {
    height: auto !important;
    position: static;
  }
}

.gallery__slide {
  display: flex;
  width: 100%;

  @media print {
    display: block;
    position: relative !important;
  }
}

@mixin gallery__slide--narrow() {
  .gallery__slide {
    display: block;
  }
}

.gallery__image {
  flex: 1 1 auto;
  position: relative;  
}

.gallery__image-inner {
  padding-top: $gallery-image-ratio;
  height: 0;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }   

  @media print {
    height: auto;
    padding-top: 0;

    img {
      height: auto;
      margin-bottom: 2rem;
      object-fit: contain;
      page-break-before: auto;
      page-break-after: auto; 
      page-break-inside: avoid;
      position: relative;
    }
  }   
}

.gallery__content {
  flex: 0 0 auto;
  padding-left: $gutter-side-mobile-xs;
  padding-right: $gutter-side-mobile-xs;
  padding-top: 3rem + $gallery-pager-height;
  margin-left: 5.6rem;
  width: $gallery-content-width;  

  @media (min-width: $breakpoint-mobile-xs) {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;  
  } 

  @media print {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin gallery__content--narrow() {
  .gallery__content {
    margin-left: 0;
    padding-top: 5.6rem + $gallery-pager-height;
    width: 100%;

    @media print {
      padding-top: 0;
    }
  }
}

.gallery__slide-title {
  margin-top: 0;
  margin-bottom: 1.9rem;
}

.gallery__slide-text {
  max-width: $rte-max-width;
  
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.gallery__pager {
  left: auto;
  margin-top: $gallery-page-spacing;
  padding-left: $gutter-side-mobile-xs;
  padding-right: $gutter-side-mobile-xs;  
  padding-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: $gallery-content-width;

  @media (min-width: $breakpoint-mobile-xs) {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;
  }  
}

@mixin gallery__pager--narrow() {
  .gallery__pager  {
    left: 0;
    padding-top: $gallery-image-ratio;
    right: auto;
    margin-top: 3rem;
    width: 100%;

    @media print {
      display: none;
    }    
  }
}

.gallery__pager-status {
  align-self: center;
  flex: 0 0 auto;
  @include font-size(14, 1.2);
}

.gallery__pager-inner {
  background-color: $color-midnight;
  border-radius: 2px;
  color: $color-pigeon;
  display: flex;
  justify-content: space-between;  
  height: $gallery-pager-height;  
  font-family: $font-montserrat;
  font-weight: $font-weight-medium;
  margin-left: -$gallery-page-spacing;
  margin-right: -$gallery-page-spacing;
  padding-left: $gallery-page-spacing;
  padding-right: $gallery-page-spacing;

  strong {
    font-weight: $font-weight-extrabold;
  }
}

.gallery__prev-next {
  align-items: center;
  appearance: none;
  background-color: $color-bismark;
  border: none;
  border-radius: 4px 4px 0 0;
  color: $color-white;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  font-family: $font-montserrat;
  font-weight: $font-weight-extrabold;
  height: 100%;
  margin-top: -$gallery-page-spacing;
  text-align: center;
  text-transform: uppercase;
  transition: all $transition-duration-default ease;
  width: 12rem;
  @include jam-focus();

  &:hover {
    background-color: $color-bismark-dark;
  }
}

.gallery__prev-next--prev {
  svg {
    margin-right: 4px;
  }
}

.gallery__prev-next--next {
  svg {
    margin-left: 4px;
  }
}

@media (max-width: $breakpoint-notebook - 1px) {
  @include gallery__slide--narrow();
  @include gallery__content--narrow();
  @include gallery__pager--narrow();
}

@media (min-width: $breakpoint-notebook) {
  .col-md-7 .col-md-6,
  .col-md-7 .col-md-7,
  .col-md-7 .col-md-8,
  .col-md-7 .col-md-9,
  .col-md-7 .col-md-10,
  .col-md-8 .col-md-6,
  .col-md-8 .col-md-7,
  .col-md-8 .col-md-8,
  .col-md-8 .col-md-9,
  .col-md-9 .col-md-6,
  .col-md-9 .col-md-7,
  .col-md-9 .col-md-8,
  .col-md-1, 
  .col-md-2, 
  .col-md-3, 
  .col-md-4,
  .col-md-5,
  .col-md-6 {    
    @include gallery__slide--narrow();
    @include gallery__content--narrow();
    @include gallery__pager--narrow();
  }
} 