// Footer component

// primary
.footer__primary {
  background-color: $color-mercury;
  border-bottom: 1px solid rgb(170, 170, 170);
  border-top: 10px solid $color-energy;
  padding-top: 3rem;
  padding-bottom: 2.6rem;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 4.5rem;
    padding-bottom: 2.1rem;
  }
}

.footer__primary-wrapper {
  @include container();
}

.footer__primary-inner {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
  }
}

.footer__mobile-navigation {
  margin-bottom: 1rem;
  padding-left: 5rem;

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}

.footer__info {
  flex: 1 1 auto;

  @media (min-width: $breakpoint-notebook) {
    padding-right: $grid-gutter-width / 2;
  }
}


.footer__contact {
  flex: 1 1 auto;
  
  @media (min-width: $breakpoint-notebook) {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}

.footer__desktop-navigation {
  flex: 1 1 auto;
  padding-left: $grid-gutter-width / 2;
}

.footer__logo {
  height: 4rem;
  margin-bottom: 3.7rem;
  margin-top: 0.5rem;
  width: 20rem;

  svg {
    height: 100%;
    width: 100%;
    vertical-align: top;
  }

  @media (max-width: $breakpoint-notebook) {
    display: none;
  }
}

.footer__logo-link {
  display: block;
}

.footer__address,
.footer__contact-item {
  margin-bottom: 4.8rem;
  padding-left: 5rem;
  position: relative;

  > svg {
    height: 2.4rem;
    left: 0;
    padding: 0.2rem;
    position: absolute;
    top: 3px;
    width: 2.4rem;

    * {
      fill: $color-silver;
    }
  } 
}

.footer__address {
  @include font-size(16, 2);

  a {
    display: inline-block;
    font-weight: $font-weight-semibold;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-desktop) {
    margin-left: -4.8rem;
  }   
}

.footer__contact-item {
  &:last-child {
    margin-bottom: 0;
  }
}

.footer__contact-value {
  font-weight: $font-weight-bold;
  @include font-size(22, (32/22));

  a {
    display: inline-block;
    margin-top: -0.6rem;
    margin-bottom: -0.6rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

.footer__follow-label {
  font-family: $font-ibm-plex-condensed;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  @include font-size(16, 2);
}

.footer__follow-links {
  list-style: none;
  margin: 0 0 0 -1rem;
  padding: 0;
}

.footer__follow-links-item {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.footer__follow-links-link {
  height: 4.4rem;
  display: block;
  padding: 1rem;
  width: 4.4rem;

  svg {
    height: 100%;
    vertical-align: top;
    width: 100%;

    * {
      fill: $color-bismark;
    }
  }
}

.footer__desktop-navigation {
  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

// Secondary
.footer__secondary {
  background-color: rgb(217, 217, 217);
  padding-top: 1rem;
  padding-bottom: 2rem;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 1.2rem;
  }  
}

.footer__secondary-inner {
  @include container();

  @media (min-width: $breakpoint-notebook) {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer__language-switcher {
  margin-bottom: 1.6rem;

  @media (min-width: $breakpoint-notebook) {
    flex: 1 0 auto;
    text-align: right;
  }   
}

.footer__copyright {
  flex: 0 0 auto;
  width: 100%;
}

.footer__disclaimer {
  color: $color-nero;
  font-style: italic;
  margin-bottom: 1.8em;
  @include font-size(14, 1.8);

  img {
    float: left;
    margin-bottom: 0.3rem;
    margin-right: 1.4rem;
    width: 10rem;
  }

  @media (min-width: $breakpoint-notebook) {
    flex: 1 1 auto;
    margin-right: $grid-gutter-width;
    margin-bottom: 1.1rem;
    margin-top: 0.8rem;
    max-width: 82.3rem;
    width: 66rem;
    order: -1;

    img {
      margin-right: 1.8rem;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    flex: 0 0 auto;
    width: 82.3rem;
  }
}

.footer__copyright {
  color: $color-nero;
  @include font-size(14, 2.1);
}