.embed-collapsible {
  // Adjust margin to match RTE li
  margin-top: -$default-rte-margin + 0.37rem;
  margin-bottom: -$default-rte-margin + 0.37rem;
}

.embed-collapsible__title {
  margin-top: 0;
  margin-bottom: 0;
}

.embed-collapsible__toggle {
  background: none;
  border: none;
  border-radius: 2px; 
  color: $color-bismark;
  font-family: $font-ibm-plex;
  font-weight: $font-weight-regular;
  margin: -7px -4px;
  padding: 7px 4px;
  position: relative;
  text-align: left;
  text-decoration: underline;  
  transition: all $transition-duration-default ease;
  @include font-size(18, $line-height-base); 
  @include jam-focus;

  &:hover {
    background-color: rgba($color-pigeon, 0.6);
  }

  &:focus {
    background-color: $color-azalea;
    color: $color-black;
    outline: 0;
  }

  svg {
    margin-left: 0.9rem;
    vertical-align: bottom;

    * {
      fill: $color-bismark;
    }
  }
}

.embed-collapsible__toggle-icon--expand {
  display: inline;
  
  .embed-collapsible--active & {
    display: none;
  } 

  @media print {
    display: none;
  }
}

.embed-collapsible__toggle-icon--collapse {
  display: none;

  .embed-collapsible--active & {
    display: inline;
  } 

  @media print {
    display: inline;
  }
}

.embed-collapsible__contents {
  display: none;
  
  .embed-collapsible--active & {
    display: block;
    margin-top: 2.9rem;
  } 

  @media print {
    display: block;
  }
}

.embed__caption {
  margin-top: 2.4rem;
  margin-bottom: -$default-rte-margin;
}

// bullet option
.embed-collapsible__list-item {
  display: list-item;  
  margin-left: 4.5rem;
  padding-left: 0.8rem;
}