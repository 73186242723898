.child-count__rte {
  .rte {
    max-width: 100%;
  }

  .pull-right {
    @media (min-width: $breakpoint-notebook) {
      margin-left: $grid-gutter-width;
      padding-right: 5rem;
      width: 43rem;

      figure {
        margin-top: 0;
      }
    }
  }

  hr {
    max-width: 22rem;
  }
}

.child-count__form {
  margin-bottom: $default-margin;
}

.child-count__group {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    width: 100%;
  }
}

.child-count__group--name,
.child-count__group--contact {
  @media (min-width: $breakpoint-notebook) {
    max-width: 98rem;  
  }
}

.child-count__group-input {
  flex: 1 1 auto;
  margin-bottom: $default-rte-margin * 1.4;;

  @media (min-width: $breakpoint-notebook) {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}

.child-count__group-input--phone {
  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    width: 39.2rem;    
  }
}

.child-count__group-input--email {
  flex: 1 1 auto;
}

.child-count__group-input--first-name,
.child-count__group-input--last-name {
  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    width: 50%;
  }
}

.child-count__group-input--comments {
  textarea {
    min-height: 25rem;
  }
}

.child-count__group--state {
  @media (min-width: $breakpoint-notebook) {
    max-width: 58.8rem;
  }
}

.child-count__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.child-count__fieldset-legend {
  font-style: italic;
  margin-bottom: 2rem;
}