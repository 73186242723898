.search-form {
  padding: 2rem 0 2.6rem;
  margin-bottom: 2rem;
  position: relative;

  &:before {
    background-color: rgb(182, 217, 220);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 4rem;
    padding: 2.9rem 0 5rem;
  }
}

.search-form__form {
  @media (min-width: $breakpoint-notebook) {
    align-items: flex-end;
    display: flex;
  }
}

.search-form__group {
  margin-bottom: 2rem;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

.search-form__group--keyword {

  input[type="text"] {
    padding-right: 6.4rem;
  }

  @media (min-width: $breakpoint-notebook) {
    flex: 1 1 auto;
  }
}

.search-form__group--context {
  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
  }
}

button[type="submit"].search-form__submit {
  background-color: $color-jelly;
  min-width: 15rem;
  padding: 1.4rem 1.8rem 1rem;

  &:before {
    background-color: $color-nordic;
  }

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
  }
}

.search-form__clear-input-wrapper {
  position: relative;
}

.search-form__clear {
  align-items: center;
  background-color: transparent;
  border: none;
  display: none;
  justify-content: center;
  right: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 6.4rem;

  &:hover {
    svg circle {
      fill: $color-jelly-dark;
    }
  }

  &.search-form__clear--visible {
    display: flex;
  }
}