.breadcrumbs__wrapper {
  @include container();
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  margin-top: 0;
  margin-bottom: -$page-title-margin-top;
  padding: 0.8rem 0 0.7rem;
  position: relative;
  z-index: 3;
}

.breadcrumbs__item {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  @include font-size(14, (30/14));
}

.breadcrumbs__link {
  display: block;
  flex: 0 0 auto;
}

.breadcrumbs__separator {
  align-items: center;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
}