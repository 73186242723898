.cta-simple {
  align-items: flex-start;

  @media (min-width: $breakpoint-notebook) {
    .col-md-12 &,
    .col-md-11 &,
    .col-md-10 & {
      display: flex; 
    }
    
    .col-md-7 &,
    .col-md-8 &,
    .col-md-9 & {
      display: flex;

      .col-md-5 &,
      .col-md-6 &,
      .col-md-7 &,
      .col-md-8 &,
      .col-md-9 &,
      .col-md-10 &,
      .col-md-11 & {
        display: block;
      }
    } 

    .col-md-1 &, 
    .col-md-2 &, 
    .col-md-3 &, 
    .col-md-4 &,
    .col-md-5 &,
    .col-md-6 & {
      display: block;
    }
  }   

  @media print {
    border-left: 5px solid $color-medium-gray; 
    padding-left: 15px;
    page-break-before: auto;
    page-break-after: auto; 
    page-break-inside: avoid;     

    &.cta-simple--midnight {
      border-color: $color-midnight; 
    }
    &.cta-simple--energy {
      border-color: $color-energy; 
    }  
    &.cta-simple--persimmon {
      border-color: $color-persimmon; 
    }  
    &.cta-simple--jelly {
      border-color: $color-jelly; 
    }              
  }
}

.block--call-to-action.block--title-knockout {
  .cta-simple__title {
    @include fancy-h3();
  }

  .cta-simple--midnight .cta-simple__title {
    background-color: $color-midnight;
  }

  .cta-simple--energy .cta-simple__title {
    background-color: $color-energy;
    color: $color-black;
  }

  .cta-simple--persimmon .cta-simple__title {
    background-color: $color-persimmon;
  }

  .cta-simple--jelly .cta-simple__title {
    background-color: $color-jelly;
  } 
}

.cta-simple__title.cta-simple__title--mobile.block__title {
  margin-top: 0;
  margin-bottom: 1.6rem;

  @media (min-width: $breakpoint-tablet) {
    display: none;
  }

  @media (min-width: $breakpoint-notebook) {
    .col-md-12 &,
    .col-md-11 &,
    .col-md-10 & {
      display: none;         
    }
    
    .col-md-7 &,
    .col-md-8 &,
    .col-md-9 & {
      display: none;

      .col-md-5 &,
      .col-md-6 &,
      .col-md-7 &,
      .col-md-8 &,
      .col-md-9 &,
      .col-md-10 &,
      .col-md-11 & {
        display: inline-block;       
      }
    } 

    .col-md-1 &, 
    .col-md-2 &, 
    .col-md-3 &, 
    .col-md-4 &,
    .col-md-5 &,
    .col-md-6 & {
      display: inline-block;
    }
  }    
}

.cta-simple__images {
  flex: 0 0 60%;
  margin-bottom: 3.2rem;
  position: relative;

  &:before {
    background-color: $color-midnight;
    content: "";
    height: 100%;
    left: -1rem;
    position: absolute;
    right: -1rem;
    top: 1rem;
    z-index: -1;
  }

  &.cta-simple__images--energy {
    &:before {
      background-color: $color-energy;
    }
  }  

  &.cta-simple__images--jelly {
    &:before {
      background-color: $color-jelly;
    }
  }

  &.cta-simple__images--midnight {
    &:before {
      background-color: $color-midnight;
    }
  }  

  &.cta-simple__images--persimmon {
    &:before {
      background-color: $color-persimmon;
    }
  }    

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-right: 3.5rem;
  }  

  @media (min-width: $breakpoint-notebook) {
    .col-md-12 &,
    .col-md-11 &,
    .col-md-10 & {
      margin-right: 3.5rem;
    }
    

    .col-md-7 &,
    .col-md-8 &,
    .col-md-9 &, {
      margin-right: 3.5rem;

      .col-md-7 &,
      .col-md-8 &,
      .col-md-9 &,
      .col-md-10 &,
      .col-md-11 & {
        margin-right: 0;
      }
    } 

    .col-md-1 &, 
    .col-md-2 &, 
    .col-md-3 &, 
    .col-md-4 &,
    .col-md-5 &,
    .col-md-6 & {
      margin-right: 0;
    }
  }  
}

.cta-simple__title--desktop.block__title {
  display: none;
  margin-top: 0.1rem;
  margin-bottom: 2.2rem;  

  @media (min-width: $breakpoint-tablet) {
    display: block;
  }

  @media (min-width: $breakpoint-notebook) {
    .col-md-12 &,
    .col-md-11 &,
    .col-md-10 & {
      display: block;
    }
    
    .col-md-7 &,
    .col-md-8 &,
    .col-md-9 &, {
      display: block;

      .col-md-5 &,
      .col-md-6 &,
      .col-md-7 &,
      .col-md-8 &,
      .col-md-9 &,
      .col-md-10 &,
      .col-md-11 & {
        display: none;
      }
    } 

    .col-md-1 &, 
    .col-md-2 &, 
    .col-md-3 &, 
    .col-md-4 &,
    .col-md-5 &,
    .col-md-6 & {    
      display: none;
    }
  }    

  @media (max-width: $breakpoint-tablet - 1px) {
    display: none;
  } 
}

.cta-simple__image {
  vertical-align: middle;
  width: 100%;
}

.cta-simple__summary {
  @include font-size(16, 1.4);

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cta-simple__content {
}

.cta-simple__actions {
  margin-top: 2.6rem;
}

.cta-simple__action {

}

// hide block title

.block--call-to-action.block--title-hidden {
  &.col-md-1, 
  &.col-md-2, 
  &.col-md-3, 
  &.col-md-4,
  &.col-md-5,
  &.col-md-6,
  &.col-md-7,
  &.col-md-8,
  &.col-md-9,
  &.col-md-10,
  &.col-md-11,
  &.col-md-12 {  
    &,
    .col-md-1, 
    .col-md-2, 
    .col-md-3, 
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {  
      .cta-simple__title.block__title {
        &,&.block__title--knockout {
          display: none;
        }
      }
    }      
  }  
} 