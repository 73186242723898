.page-title__wrapper {
 
}

.page-title {
  margin-bottom: $page-title-margin-top;
  @include font-size(32, $line-height-heading);

  @media (min-width: $breakpoint-notebook) {
    @include font-size(44, 1.4);
  }
}

.page-title--lengthy {
  @media (max-width: $breakpoint-notebook - 1px) {
    @include font-size(28, $line-height-heading);
  }
}

.page-title__supertitle {
  margin-bottom: -0.8rem;
  margin-top: 3rem;
  text-transform: uppercase;
  @include font-size(16, 1.4);

  @media (max-width: $breakpoint-tablet - 1px) {
    margin-top: 1.5rem;
    margin-bottom: -1.3rem;
    @include font-size(14, 1.6);
  }    
}

.page-title__supertitle-separator {
  display: inline-block;
  text-align: center;
  width: 3.5rem;

  @media (max-width: $breakpoint-tablet - 1px) {
    display: none;
  }  
}

.page-title__type {
  @media (max-width: $breakpoint-tablet - 1px) {
    display: block;
  }
}