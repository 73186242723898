body.mobile-navigation--active {
  position: fixed;
}

.mobile-navigation {
  background-color: $color-white;
  bottom: 0;
  left: 0;
  height: 100vh;
  top: 0;
  position: fixed;
  overflow: auto;
  right: 0;
  transition: all $transition-duration-default ease;
  width: 100vw;
  z-index: 5;
  -webkit-overflow-scrolling: touch;

  opacity: 0;
  visibility: hidden;

  .mobile-navigation--active & {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}

.mobile-navigation__inner {
  padding-bottom: 3.6rem;
  @include container();
}

.mobile-navigation__actions {
  text-align: right;
  margin-bottom: 2rem;
}

.mobile-navigation__close {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: $color-bismark;
  display: inline-block;
  font-weight: $font-weight-bold;
  padding: 0.9rem 0.8rem;
  text-transform: uppercase;
  @include font-size(12, 1);
  @include jam-focus();

  @media (min-width: $breakpoint-mobile-xs) {
    margin-right: -1.2rem;
  }

  svg,span {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    height: 3.2rem;
    padding: 0.6rem;
    width: 3.2rem;
  }

  &:hover {
    background-color: rgba($color-pigeon, 0.6);
  }  

  &:focus {
    background-color: $color-azalea;
    color: $color-black;

    svg * {
      fill: $color-black;
    }
  }
}

.mobile-navigation__nav {
  margin-top: 3.6rem;
}

.mobile-navigation__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-navigation__menu-item {
  margin: 0 0 5rem;
  padding: 0;
  @include font-size(24, 1.25);
}

.mobile-navigation__menu-link {
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
}

.mobile-navigation__submenu {
  list-style: none;
  margin: 3rem 0 0;
  padding: 0 0 0 2rem;
}

.mobile-navigation__submenu-item {
  margin: 0 0 3rem;
  padding: 0;
  @include font-size(16, 1.25);
}

.mobile-navigation__submenu-link {
  font-family: $font-ibm-plex-condensed;
}