$info-pair-icon-padding: 3rem;
.info-pair {
  margin-bottom: $default-margin;
  margin-top: $default-margin;
}

.info-pair__list {
  margin-bottom: 0;
  margin-top: 0;
}

.info-pair__label {
  color: $color-persimmon;
  font-family: $font-ibm-plex-condensed;
  font-weight: bold;
  text-transform: uppercase;
  @include font-size(16, 1.8);
}

.info-pair__label--with-icon {
  margin-bottom: 0.1rem;
  padding-left: $info-pair-icon-padding;
  position: relative;

  svg {
    left: 0;
    height: 24px;
    padding: 2px;
    position: absolute;
    top: 2px;
    width: 24px;

    * {
      fill: $color-persimmon;
    }
  }

  span {

  }
}

.info-pair__value {
  margin-bottom: 2.8rem;
  margin-left: 0;

  .info-pair__label--with-icon + & {
    padding-left: $info-pair-icon-padding;
  }
} 

.info-pair__value-date {
  margin-bottom: 0.6rem;
  @include font-size(32, 1.3);
}

.info-pair__value-time {
  @include font-size(20, 1.25);
}

.info-pair__actions {
  padding-left: $info-pair-icon-padding;
}