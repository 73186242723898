.microsite {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
  }
}

.microsite__main {
  @media (min-width: $breakpoint-notebook) {
    flex: 1 1 auto;
  }  
}

.microsite__navigation {
  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    height: 100vh;
    order: -1;
    overflow: auto;
    position: sticky;
    top: 0;
    width: 26rem;
  } 
}