.microsite__navigation {
  background-color: rgb(250, 250, 250);
  border-top: 1px solid $color-mercury;
  margin-top: 0;
  padding-top: 3rem;
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  padding-bottom: 1px;

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    padding-left: $gutter-side-mobile-xs;
    padding-right: $gutter-side-mobile-xs;
  }

  @media (min-width: $breakpoint-notebook) {
    border-top: 0;
    border-right: 1px solid $color-mercury;
    flex: 0 0 auto;
    order: -1;
    padding: 2.3rem 0.8rem 0 1rem;
  }
}

.microsite-navigation__title {
  font-family: $font-ibm-plex-condensed;
  letter-spacing: 2.7px;
  margin-bottom: 2.9rem;
  text-transform: uppercase;
  @include font-size(18, 1.4);

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}

.microsite-navigation__list {
  list-style: none;
  margin: 0;
  padding: 0 0 $default-margin 3rem;
  position: relative;

  @media (min-width: $breakpoint-notebook) {
    padding-left: 2rem;
  }    
}

.microsite-navigation__list-item {
  margin-bottom: 0;
  padding: 0;
  @include font-size(20, 1.2);  

  @media (min-width: $breakpoint-notebook) {
    @include font-size(16, 1.25);
  }  
}

.microsite-navigation__link {
  display: inline-block;  
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-bold;
  margin-right: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}

// sublists
.microsite-navigation__list--sublist {
  padding: 0 0 0 2rem;
  position: static;

  @media (min-width: $breakpoint-notebook) {
    position: relative;
  }
}

.microsite-navigation__list-item--sublist-item {
  @include font-size(16, 1.25);   
}

.microsite-navigation__link--sublink {
  font-weight: $font-weight-regular;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

// states
.microsite-navigation__link--active {
  &:before {
    background-image: url('../core/images/current-arrow.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px 16px;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    width: 16px;
  }

  @media (min-width: $breakpoint-notebook) {
    &:before {
      background-size: 12px 12px;
      height: 20px;
      width: 12px;
    }
  }
}

.microsite-navigation__link--sublink.microsite-navigation__link--active {
  &:before {
    height: 21px;
  }

  @media (min-width: $breakpoint-notebook) {
    &:before {
      height: 20px;
    }
  }  
}

.microsite-navigation__link--has-children {
  padding-right: 2rem;

  &:after {
    background-image: url('../core/images/children-arrow.png');
    background-repeat: no-repeat;
    background-size: 11px 6px;
    background-position: center center;
    content: "";
    height: 6px;
    display: inline-block;
    margin-left: 5px;
    margin-right: -16px;
    width: 11px;
  }
}

.microsite-navigation__link--expanded {
  &:after {
    background-image: url('../core/images/expanded-arrow.png');
    height: 8px;
  }
}

.microsite-navigation__link--active.microsite-navigation__link--expanded {
  &:after {
    display: none;
  }
}

.microsite-navigation__link--sublink.microsite-navigation__link--active {
  &:after {
    height: 21px;
  }
}

// help links
.microsite-navigation__help {
  @include element-invisible();
}

// actions / top link
.microsite-navigation__actions {
  margin-top: 5.5rem;
  margin-bottom: 6rem;
  padding-left: 3rem;

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }  
}

.microsite-navigation__action {
  @include font-size(18, 1.8);
}