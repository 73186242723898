// Mixins

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin font-size($font-size: $font-size-base, $line-height: $line-height-base) {
  font-size: $font-size * ($font-size-root-px / 10);

  // Restore rem font-size rule once Chrome fixes sizing bug.
  // https://code.google.com/p/chromium/issues/detail?id=319623
  font-size: $font-size / 10 + rem;
  line-height: $line-height;
}

@mixin element-invisible {
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@mixin container() {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-site-width;
  width: 100%;

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    padding-left: $gutter-side-mobile-xs;
    padding-right: $gutter-side-mobile-xs;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: $gutter-side-tablet;
    padding-right: $gutter-side-tablet;
  }

  @media (min-width: $breakpoint-notebook) {
    padding-left: $gutter-side-notebook;
    padding-right: $gutter-side-notebook;
  } 

  @media (min-width: $breakpoint-desktop) {
    padding-left: $gutter-side-desktop;
    padding-right: $gutter-side-desktop;
  } 
}

@mixin jam-focus() {
  box-shadow: 0 0 0 2px transparent,
              0 0 0 6px transparent,
              0 0 0 $jam-focus-width transparent;

  &:focus {
    @include jam-focus-inner();
  }
}

@mixin jam-focus-inner() {
  box-shadow: 0 0 0 2px rgb(229, 149, 184),
                0 0 0 6px rgba($color-jam, 0.85),
                0 0 0 $jam-focus-width rgba($color-jam, 0.25);
}

@mixin fancy-h2() {
  position: relative;
  
  &:after {
    background-color: $color-mineshaft;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  span {
    border-top: 5px solid $color-mineshaft;
    display: inline-block;
  }  

  .section-style-oyster & {
    span {
      border-color: $color-jelly;
    }

    &:after {
      background-color: $color-jelly;
    }
  }

  .section-style-beeswax & {
    span {
      border-color: $color-bronze;
    }

    &:after {
      background-color: $color-bronze;
    }
  } 

  .section-style-pigeon & {
    span {
      border-color: $color-bismark;
    }

    &:after {
      background-color: $color-bismark;
    }
  } 

  .section-style-salmon & {
    span {
      border-color: $color-persimmon;
    }

    &:after {
      background-color: $color-persimmon;
    }
  }   

  // block containers with colored bgs
  .block--container--bg--oyster & {
    span {
      border-color: $color-jelly;
    }

    &:after {
      background-color: $color-jelly;
    }
  }   

  .block--container--bg--beeswax & {
    span {
      border-color: $color-bronze;
    }

    &:after {
      background-color: $color-bronze;
    }
  }   

  .block--container--bg--mercury & {
    span {
      border-color: $color-black;
    }

    &:after {
      background-color: $color-black;
    }
  }  

  .block--container--bg--pigeon & {
    span {
      border-color: $color-bismark;
    }

    &:after {
      background-color: $color-bismark;
    }
  }  

  .block--container--bg--salmon & {
    span {
      border-color: $color-persimmon;
    }

    &:after {
      background-color: $color-persimmon;
    }
  }      
}

@mixin fancy-h3() {
  background-color: $color-nero;
  color: $color-white;
  display: inline-block;  
  margin-bottom: calc(#{$default-margin} - 1rem);  
  margin-left: -$gutter-side-mobile-xs;
  margin-top: 0;  
  padding: 0.7rem $gutter-side-mobile 0.8rem;

  @media (min-width: $breakpoint-mobile-xs) {
    margin-left: -$gutter-side-mobile;
  }   

  .block--container-grouped & {
    @media (max-width: $breakpoint-tablet - 1px) {
      margin-left: -$gutter-side-mobile / 2;
    } 

    @media (max-width: $breakpoint-mobile-xs - 1px) {  
      margin-left: -$gutter-side-mobile-xs;
    }
  }

  .block--container-grouped > & {
    @media (min-width: $breakpoint-tablet) {
      margin-left: -$gutter-side-mobile + ($grid-gutter-width / 2);
    }
  }

  .block--container-grouped.col-md-12 > & {
    @media (min-width: $breakpoint-desktop) {
      margin-left: -$gutter-side-mobile + ($grid-gutter-width / 2) + $block-container-grouped-desktop-margin;
    }     
  } 
  
  .section-style-oyster & {
    background-color: $color-jelly;
  }

  .section-style-beeswax & {
    background-color: $color-bronze;
  } 

  .section-style-pigeon & {
    background-color: $color-bismark;
  } 

  .section-style-salmon & {
    background-color: $color-persimmon;
  } 

  .block--container--bg--oyster & {
    background-color: $color-jelly;
  }

  .block--container--bg--beeswax & {
    background-color: $color-bronze;
  } 

  .block--container--bg--mercury & {
    background-color: $color-nero;
  }

  .block--container--bg--pigeon & {
    background-color: $color-bismark;
  }

  .block--container--bg--salmon & {
    background-color: $color-persimmon;
  }    
}

@mixin button-submit() {
  background-color: $color-bismark;
  border: none;
  border-radius: 4px 4px 0 0;
  color: $color-white;
  font-family: $font-montserrat;
  font-weight: $font-weight-extrabold;
  margin-bottom: $button-bg-offset;
  margin-left: $button-bg-offset;
  margin-right: $button-bg-offset;
  min-width: 20rem;
  outline: 0;
  padding: 1.8rem;
  position: relative;
  text-align: center;
  transition: all $transition-duration-default;
  @include font-size(20, 1.2);
  @include jam-focus();

  &:hover {
    background-color: rgb(53, 74, 100);
  }

  &:before {
    background-color: $color-midnight;
    border-radius: 2px;
    bottom: -$button-bg-offset;
    content: "";
    height: 100%;
    left: -$button-bg-offset;
    right: -$button-bg-offset;
    position: absolute;
    z-index: -1;
  }

  &:active {
    bottom: 0;
  }

  &.disabled,
  &[disabled] {
    background-color: $color-mercury;

    &:before {
      background-color: rgb(179, 179, 179);
    }
  }
}

@mixin scrolling-shadow-base() {
  content: '';
  height: 5px;
  background-repeat: no-repeat;
  background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), rgba(0,0,0,0));
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  transition: all 0.1s linear;
  z-index: 3;  
}

@mixin scrolling-shadow-top() {
  @include scrolling-shadow-base();
  top: 0;
}

@mixin scrolling-shadow-bottom() {
  @include scrolling-shadow-base();
  bottom: 0;
  background: radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), rgba(0,0,0,0));
}