// CTA Links

.link {
  display: inline-block;
  font-family: $font-montserrat;
  font-weight: $font-weight-bold;
  min-width: 26rem;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  padding: 1.8rem 3.5rem 1.8rem 5rem;
  position: relative;
  text-align: center;
  transition: background-color $transition-duration-default ease;
  vertical-align: top;
  @include font-size(20, 1.2);

  // background skew
  &:before {
    border-radius: 0 2px 0 0;
    bottom: 0;
    content: "";
    position: absolute;
    left: 2.2rem;
    top: 0;
    transition: background-color $transition-duration-default ease;
    transform: skew(-20.6deg);
    width: calc(100% - 3.3rem);
    z-index: -1;
  }  

  // skew accent
  &:after {
    border-radius: 4px 0 0 4px;
    bottom: 0;
    content: "";
    left: -1.2rem;
    position: absolute;
    top: 0;
    transition: background-color $transition-duration-default ease;
    transform: skew(-20.6deg);
    width: 4rem;
    z-index: -1;
  }

  &:hover,
  &:focus {
    background-color: unset;
  }

  @media print {
    line-height: 1.4;
    padding: 0;
    text-align: left;

    &:before {
      display: none;
    }

    &:after {
      position: static;
      transform: none;
    }
  }
}

.link.link--midnight {
  color: $color-white;

  &:before {
    background-color: $color-bismark;  
  }

  &:after {
    background-color: $color-midnight;
  }

  &:hover {

    &:before {
      background-color: $color-bismark-dark;  
    }    
  }
}

.link.link--jelly {
  color: $color-white;

  &:before {
    background-color: $color-jelly;  
  }

  &:after {
    background-color: $color-nordic;
  }

  &:hover {

    &:before {
      background-color: $color-jelly-dark;  
    }    
  } 
}

.link.link--energy {
  color: $color-black;

  &:before {
    background-color: $color-beeswax;  
  }

  &:after {
    background-color: $color-energy;
  }

  &:hover {

    &:before {
      background-color: $color-beeswax-dark;  
    }    
  } 
}

.link.link--persimmon {
  color: $color-black;

  &:before {
    background-color: $color-salmon;  
  }

  &:after {
    background-color: $color-persimmon;
  }

  &:hover {

    &:before {
      background-color: $color-salmon-dark;  
    }    
  }   
}