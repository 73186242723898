// Rich Text Elements

.rte {
  max-width: $rte-max-width;
  word-break: break-word;
  width: 100%;
}

.rte h2 {
  @include fancy-h2();
}

.rte h3.knockout {
  span {
    margin-top: 0;
    margin-bottom: 0;
    @include fancy-h3();
  }
}

.rte {
  p,h1,h2,h3,h4,h5,h6,ul,ol,blockquote {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Do not display special styling for anchors
.rte a:not([href]) {
  color: inherit;

  &:hover {
    background: none;
  }
}

p.lead {
  font-family: $font-ibm-plex-condensed;
  font-weight: $font-weight-medium;
  font-style: italic;
  @include font-size(24, 1.35);
}

.rte figure {
  margin-top: $default-margin;
}

.rte figure,
.rte blockquote {

  @media (max-width: $breakpoint-tablet - 1px) {
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;

    .block--container-grouped & {
      margin-left: -$gutter-side-mobile / 2;
      margin-right: -$gutter-side-mobile / 2;
    }   
  }

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    margin-left: -$gutter-side-mobile-xs;
    margin-right: -$gutter-side-mobile-xs;

    .block--container-grouped & {
      margin-left: -$gutter-side-mobile-xs;
      margin-right: -$gutter-side-mobile-xs;
    }      
  }

  @media print {
    &,
    .block--container-grouped & {
      margin-left: 0;
      margin-right: 0;
    }
  } 
}

.rte figure {
  margin-top: $default-rte-margin;
}

.rte blockquote {
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  } 

  &.rte__blockquote-indented {
    border-left: none;
    color: $color-mineshaft;
    font-family: $font-ibm-plex;
    font-weight: $font-weight-regular;
    font-style: normal;
    padding-left: 4.5rem;
    padding-right: 0;
    @include font-size(18, 1.8);  
  }
}

.pull-left,
.pull-right {
  @media (max-width: $breakpoint-tablet - 1px) {
    margin: 0;
  }

  @media (min-width: $breakpoint-tablet) {
    max-width: 21rem;

    figure {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.pull-left {
  @media (min-width: $breakpoint-tablet) {
    float: left;
    margin-right: 2rem;
  }
}

.pull-right {
  @media (min-width: $breakpoint-tablet) {
    float: right;
    margin-left: 2rem;
  }
}

.pull-center {
  figure {
    text-align: center;
  }
}

hr {
  &.rte__hr--bismark {
    border-color: $color-bismark;
  }
  &.rte__hr--persimmon {
    border-color: $color-persimmon;
  }
  &.rte__hr--jelly {
    border-color: $color-jelly;
  }
  &.rte__hr--mineshaft {
    border-color: $color-mineshaft;
  }  
}

.rte__table-wrapper {
  position: relative;
  margin-top: $default-rte-margin;
  margin-bottom: $default-rte-margin;
  overflow: hidden; 

  @media (min-width: $breakpoint-mobile-xs) {
    margin-left: -$table-padding;
    margin-right: -$table-padding;
  }
}

.rte__table-wrapper-inner {
  max-width: 100%;
  overflow: auto;

  &:before,
  &:after {
    background-color: rgba(0,0,0,0.6);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5),
                inset 0 1px 3px 0 rgba(0,0,0,0.5);
    bottom: 0;
    content: "";
    filter: blur(5px);
    opacity: 1;
    position: absolute;
    top: 0;
    transition: all $transition-duration-default ease;
    visibility: visible;
    width: 20px;
  }

  &:before {
    border-radius:0 100% 100% 0;
    left: -14px;
  }

  &:after {
    border-radius: 100% 0 0 100%;
    right: -14px;
  }

  &.at-left {
    &:before {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.at-right {
    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }   
}

.rte__diminished {
  @include font-size(14, 1.6);
}