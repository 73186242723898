.block {
  margin-bottom: $default-rte-margin;
  margin-top: $default-rte-margin;

  &.block--section {
    margin-top: 0;
    margin-bottom: 0;
  }
  &.block--featured-content,
  &.block--queried-content {
    margin-bottom: 0;
  }  
}

h2.block__title {
  @include fancy-h2();
  margin-top: $default-rte-margin;
}

h3.block__title {
  margin-bottom: calc(#{$default-rte-margin} - 1rem + 0.8rem); 
  margin-top: 0.7rem;
}

h3.block__title.block__title--knockout {
  @include fancy-h3();  
}

.block__title {

  .block--call-to-action > & {
    display: none;
  }
}

h3.block__title {
  &,&.block__title--knockout {
    .block--call-to-action > & {
      display: none;
    }  
  }
}

.block--text {
  margin-top: 0;

  & > .block__title {
    margin-top: calc(0.7rem + #{$default-rte-margin});
    margin-bottom: -1rem + 0.8rem;

    &.block__title--knockout {
      margin-top: $default-rte-margin; 
      margin-bottom: 0;
    }
  }
}

.block--container {
  &.block--container--bg {

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -2;
    }
  }

  &.block--container--bg--oyster {
    &:after {
      background-color: $color-oyster;
    }
  }

  &.block--container--bg--beeswax {
    &:after {
      background-color: $color-beeswax;
    }  
  }  

  &.block--container--bg--mercury {
    &:after {
      background-color: $color-mercury;
    }  
  } 

  &.block--container--bg--pigeon {
    &:after {
      background-color: $color-pigeon;
    }     
  }  

  &.block--container--bg--salmon {
    &:after {
      background-color: $color-salmon;
    } 
  }

  &.block.block--container-stacked {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.block.block--container-stacked.block--container--bg {
    margin-bottom: $default-margin;
    margin-top: $default-margin;
  }
}

.block--container-grouped {
  @media (min-width: $breakpoint-mobile-xs) {
    padding-left: $gutter-side-mobile / 2;
    padding-right: $gutter-side-mobile / 2; 

    & > .block__title {
      margin-left: $gutter-side-mobile / 2;
      margin-right: $gutter-side-mobile / 2;
    }     
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;

    & > .block__title {
      margin-left: ($grid-gutter-width / 2);
      margin-right: ($grid-gutter-width / 2);
    }       
  }

  @media (min-width: $breakpoint-desktop) {

    &.col-md-12 {
      margin-left: -$block-container-grouped-desktop-margin;
      margin-right: -$block-container-grouped-desktop-margin;
      width: calc(100% + (2* #{$block-container-grouped-desktop-margin}));

      & > .block__title {
        margin-left: ($grid-gutter-width / 2) + $block-container-grouped-desktop-margin;
        margin-right: ($grid-gutter-width / 2) + $block-container-grouped-desktop-margin;
      }
    }
  }  

  @media print {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;

    & > .block__title {
      margin-left: 0;
      margin-right: 0;
    }
  }
}