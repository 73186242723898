@media print {
  .header__mobile-actions,
  .sharing,
  #admin-overlay-trigger,
  #admin-overlay,
  footer,
  .microsite-header__secondary,
  .microsite__navigation,
  .breadcrumbs__wrapper,
  .primary-navigation,
  .filter {
    display: none;
  }

  .header__logo-link:after {
    display: none !important;
  }

  h3.block__title.block__title--knockout {
    background-color: transparent;
    color: $color-mineshaft;
  }

  .staff__row {
    display: flex;

    > div {
      flex: 1 1 auto;
    }
  }

  .link {
    color: $color-mineshaft;
    overflow: visible;
    padding: 0;
    position: static;

    &:after {
      position: static;
    }
  }

  .block--section {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .section__title {
    margin-bottom: 0;
  }
}