// 
// Layout
//
// Layout structure
//

.container {
  @include container();
} 

.container-fluid {

  @media (max-width: $breakpoint-tablet - 1px) {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;
  }    

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    padding-left: $gutter-side-mobile-xs;
    padding-right: $gutter-side-mobile-xs;
  }  
}

.row {
  @media (max-width: $breakpoint-tablet - 1px) {
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;

    .block--container-grouped & {
      margin-left: -$gutter-side-mobile / 2;
      margin-right: -$gutter-side-mobile / 2;
    }     
  }

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    margin-left: -$gutter-side-mobile-xs;
    margin-right: -$gutter-side-mobile-xs;

    .block--container-grouped & {
      margin-left: -$gutter-side-mobile-xs;
      margin-right: -$gutter-side-mobile-xs;
    }    
  }  
}

.col-xs-12 {
  @media (max-width: $breakpoint-tablet - 1px) {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;

    .block--container-grouped & {
      padding-left: $gutter-side-mobile / 2;
      padding-right: $gutter-side-mobile / 2;
    }
  }

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    padding-left: $gutter-side-mobile-xs;
    padding-right: $gutter-side-mobile-xs;

    .block--container-grouped & {
      padding-left: $gutter-side-mobile-xs;
      padding-right: $gutter-side-mobile-xs;
    }    
  }    
}