.search {
  margin-bottom: $default-margin * 2;
}

.search__title {
  margin-top: 0;
  margin-bottom: 5rem;
  @include font-size(32, $line-height-heading);

  @media (min-width: $breakpoint-notebook) {
    @include font-size(44, 1.4);
  }  
}

.search__title-keyword {
  color: $color-bismark;
}

.search__result {
  margin-bottom: 4.3rem;
}

.search__results {
  max-width: $rte-max-width;
  width: 100%;
}