.staff {
  margin-bottom: $large-margin;
}

.staff__row {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
  }
}

.staff__primary {
  flex: 0 0 auto;
}

.staff__secondary {
  flex: 0 0 auto;
  order: 2;
}

.staff__image-wrapper {
  position: relative;
  width: 22rem;

  img {
    vertical-align: top;
  }

  @media (max-width: $breakpoint-mobile-xs - 1px) {
    margin-left: 1rem;
  }

  @media (min-width: $breakpoint-notebook) {
    float: left;
    margin-right: $grid-gutter-width;
    margin-bottom: 4.6rem;
    margin-top: $default-margin;
    max-width: 23.8rem;
    width: 42.857%;
  }

  &:before {
    background-color: $color-persimmon;
    content: "";
    height: 100%;
    left: -1rem;
    position: absolute;
    right: -1rem;
    top: 1rem;
    z-index: -1;
  }
}

.staff__body {

}