.language-switcher {
  @include font-size(14, (44/14));
}

.language-switcher__separator {
  display: inline-block;
  text-align: center;
  width: 2.6rem;  
}

.language-switcher__link {
  display: inline-block;

  .footer__secondary & {
    color: darken($color-bismark, 1%);
  }
}