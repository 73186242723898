.sharing {
  border-top: 1px solid $color-mercury;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;

  .at-resp-share-element .at-share-btn {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 1.5rem;
  }
}

.sharing__inner {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.addthis_inline_share_toolbox {
  flex: 0 0 auto;
  min-width: 0;
}

.sharing__text {
  margin-left: 1rem;
  @include font-size(14, 2.14);
}